<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <add-people :dialog="dialog" @close="dialog.show = false" @save="savingPeople()"></add-people>
    <base-card @addModal="openDialog" :heading="page.title" :dialog="true" :manage="true" :button="button">
      <datatable-list
        @delete="deletePeople"
        :text="text"
        :headers="headers"
        :sortBy="sortBy"
        :items="items"
        v-if="fetch && schoolFetch && courseFetch"
        :showSelect="showSelect"
        :displayActionItems="displayActionItems"
        :tableUser="true"
        :pagination="pagination"
        :loading="!fetch || !schoolFetch || !courseFetch"
        @update="pagination = { ...pagination, ...$event}"

      ></datatable-list>
      <v-skeleton-loader
        v-else
        v-bind="{
        class: 'mb-6',
        boilerplate: true,
        elevation: 2
        }"
        v-for="i in 5"
        :key="i"
        type="table-heading"
      ></v-skeleton-loader>
    </base-card>
  </v-container>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  data: () => ({
    page: {
      title: ''
    },
    fetch: false,
    schoolFetch: false,
    courseFetch: false,
    role: '',
    rolesParamsSearch: '',
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Escolas',
        disabled: false,
        to: '/school'
      }
    ],
    button: {
      show: true,
      url: '/course/add/',
      text: '...',
      icon: 'mdi-plus'
    },
    text: {
      searchLabel: 'Pesquisar Turmas',
      emptyLabel: 'Nenhuma cadastro encontrado',
      addLabel: 'Adicionar Escola',
      addUrl: '/school/add',
      importLabel: 'Importar Turmas',
      importUrl: '/school/add'
    },
    headers: [
      {
        text: 'Nome',
        value: 'name',
        align: 'start'
      },
      { text: 'Função', value: 'roles', align: 'center' }
    ],
    id: '',
    sortBy: 'data',
    showSelect: false,
    displayActionItems: false,
    items: [],
    dialog: {
      show: false
    },
    pagination: {
      page: 1,
      itemsPerPage: 15,
      prev_page_url: false,
      next_page_url: false
    },
    school: {},
    course: {}
  }),
  watch: {
    'pagination.page' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getPeople()
      }
    },
    'pagination.itemsPerPage' (oldValue, newValue) {
      if (oldValue !== newValue) {
        this.getPeople()
      }
    }
  },
  methods: {
    ...mapMutations(['setLoading']),
    buildQuery () {
      const pagination = `&page=${this.pagination.page}&per_page=${this.pagination.itemsPerPage}`
      return pagination
    },
    openDialog () {
      this.dialog.show = true
    },
    formatPeoples (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        if (value.data) {
          const obj = {
            avatar: value.data.user.avatar,
            name: value.data.user.name,
            email: value.data.user.email,
            id: value.data.user.id,
            roles: value.data.roles,
            isSelected: false
          }
          arr.push(obj)
        }
      })
      return arr
    },
    async getPeople () {
      this.fetch = false
      try {
        await this.$axios
          .get(
            `/organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people${this.rolesParamsSearch}${this.buildQuery()}`
          )
          .then(response => {
            this.items = this.formatPeoples(response.data.items)
            const pagination = response.data.pagination
            this.pagination = {
              ...this.pagination,
              total: pagination.total,
              page: pagination.current_page,
              itemsPerPage: pagination.per_page
            }
          })
      } catch (error) {
      } finally {
        this.fetch = true
      }
      // get adms
    },
    savingPeople () {
      this.dialog.show = false
      this.getPeople()
    },
    formatPeoplesSend (peoples) {
      const arr = []
      if (!peoples.length) return arr
      peoples.forEach((value, index) => {
        const obj = {
          user: {
            id: value.id
          },
          roles: [{ name: this.dialog.role, operation: 'REMOVE' }]
        }

        arr.push(obj)
      })
      return arr
    },
    async deletePeople (people) {
      // this.dialog.show = false
      this.setLoading(true)
      const self = this
      const payload = this.formatPeoplesSend(people)
      this.$axios
        .post(this.dialog.linkSave, { users: payload })
        .then(response => {
          self.$store.dispatch('alert', { msg: 'Remoção bem sucedida!' })
        })
        .catch(error => {
          const errosArray = Object.assign({}, error.response.data.errors)
          if (error.response.data.errors) {
            Object.keys(errosArray).forEach(function (item) {
              self.$store.dispatch('alert', {
                color: 'red',
                msg: errosArray[item][0]
              })
            })
          } else {
            self.$store.dispatch('alert', {
              color: 'red',
              msg: 'Erro na rede, tente novamente!'
            })
          }
        })
        .finally(() => {
          self.setLoading(false)
          self.getPeople()
        })
    },
    async getCourseData () {
      this.courseFetch = false
      await this.$axios
        .get(`/organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}`)
        .then(response => {
          this.course = response.data
          this.courseFetch = true
        })
      // get adms
    },
    async getSchoolData () {
      this.schoolFetch = false
      try {
        const classResponse = await this.$axios.get(`organizations/${this.id}/schools/${this.$route.params.id}`)

        if (!classResponse.data) {
          throw classResponse
        }

        this.school = classResponse.data
        this.schoolFetch = true
        // store.dispatch('setDataAuth', { token, classData })
      } catch (err) {
        const msg = 'Erro na conexão, tente novamente'
        this.$store.dispatch('alert', { color: 'red', msg })
      } finally {

      }
    }
  },
  async mounted () {
    const rolesParams = this.$route.params.roles
    this.id = localStorage.getItem('ACTIVE_ORGANIZATION')
    await this.getCourseData()
    await this.getSchoolData()

    this.button.url = `/course/people/${this.$route.params.id}/${this.$route.params.course}/${rolesParams}`

    if (rolesParams === 'teachers') {
      this.rolesParamsSearch = '?roles[]=TEACHER'
      this.role = 'TEACHER'
      this.page.title = 'Professores'
    } else if (rolesParams === 'students') {
      this.rolesParamsSearch = '?roles[]=STUDENT'
      this.role = 'STUDENT'
      this.page.title = 'Alunos'
    } else {
      this.page.title = 'Membros da escola'
    }
    this.breadcrumbs.push(
      {
        text: this.school.data.name,
        disabled: false,
        to: `/school/${this.$route.params.id}`
      },
      {
        text: 'Turmas',
        to: `/school/course/${this.$route.params.id}`
      },
      {
        text: this.course.data.name,
        to: `/course/${this.$route.params.id}/${this.$route.params.course}`
      },
      {
        text: this.page.title,
        disabled: true
      }
    )
    this.button.text = this.page.title
    this.text = {
      ...this.text,
      searchLabel: `Pesquisar ${this.page.title}`,
      addLabel: `Adicionar ${this.page.title}`
    }
    if (this.id) {
      // this.getPeople()
    } else {
      this.$store.dispatch('alert', {
        color: 'red',
        msg: 'Você precisa selecionar uma organização'
      })
    }
    this.dialog = {
      show: false,
      linkSave: `/organizations/${this.id}/schools/${this.$route.params.id}/courses/${this.$route.params.course}/people/sync`,
      role: this.role,
      title: this.page.title
    }
  }
}
</script>
